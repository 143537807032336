<template>
    <div style="width: 100%; overflow: hidden; position: relative" id="parent">
        <div class="left_tab_parent">
            <div class="left_tab">
                <div
                        v-for="(item, index) in tab_list"
                        :key="index"
                        :class="{ isChoose: isChoose == index }"
                        @click="showTab(index)"
                >
                    {{ item }}
                </div>
            </div>
        </div>
        <div class="right_content_parent">
            <div class="right_content">
                <component
                        :is="componentsList[isChoose]"
                        v-if="forceUpdate"
                ></component>
            </div>
        </div>
        <div class="enterprise-footer" style="text-align: center">
            <a style="color: #515a6e" href="https://beian.miit.gov.cn/" target="_blank">连云港科技大市场版权所有2013-2024
                苏ICP备18024573号-2. All Rights Reserved.</a>
        </div>
    </div>
</template>

<script>
    import $ from "jquery";

    export default {
        name: "EnterpriseSchoolConnection",
        mounted() {
            this.changeHeight();
        },
        watch: {
            startTime() {
                let self = this;
                this.endOption = {
                    disabledDate(date) {
                        if (self.startTime) {
                            return date && date.valueOf() < self.startTime;
                        }
                    },
                };
            },
            endTime() {
                let self = this;
                this.startOption = {
                    disabledDate(date) {
                        if (self.endTime) {
                            return date && date.valueOf() > self.endTime;
                        }
                    },
                };
            },
        },
        components: {
            EnterpriseDemand: () =>
                import(
                    "@/components/science/EnterpriseSchoolConnection/EnterpriseDemand.vue"
                    ),
            EnterpriseInformationMatching: () =>
                import(
                    "@/components/science/EnterpriseSchoolConnection/EnterpriseInformationMatching.vue"
                    ),
        },

        data() {
            return {
                forceUpdate: true,
                componentsList: ["EnterpriseDemand", "EnterpriseInformationMatching"],
                startTime: "",
                endTime: "",
                xqmc: "",
                sbmc: "",
                xqlx: "",
                data7: [
                    {
                        name: "John Brown",
                        age: 18,
                        address: "New York No. 1 Lake Park",
                    },
                    {
                        name: "Jon Snow",
                        age: 26,
                        address: "Ottawa No. 2 Lake Park",
                    },
                    {
                        name: "Jon Snow",
                        age: 26,
                        address: "Ottawa No. 2 Lake Park",
                    },
                    {
                        name: "Jon Snow",
                        age: 26,
                        address: "Ottawa No. 2 Lake Park",
                    },
                    {
                        name: "Jon Snow",
                        age: 26,
                        address: "Ottawa No. 2 Lake Park",
                    },
                    {
                        name: "Jon Snow",
                        age: 26,
                        address: "Ottawa No. 2 Lake Park",
                    },
                    {
                        name: "Jon Snow",
                        age: 26,
                        address: "Ottawa No. 2 Lake Park",
                    },
                ],
                columns12: [
                    {
                        title: "Name",
                        slot: "name",
                    },
                    {
                        title: "Age",
                        key: "age",
                    },
                    {
                        title: "Address",
                        key: "address",
                    },
                    {
                        title: "操作",
                        slot: "action",
                        width: 150,
                        align: "center",
                    },
                ],
                tab_height: 0,
                tab_list: ["企业需求", "企业信息匹配"],
                isChoose: 0,
                contentIndex: 0,
                startOption: {},
                endOption: {},
                keyword: "",
            };
        },
        methods: {
            showTab(index) {
                this.forceUpdate = false;
                this.isChoose = index;
                this.$nextTick(() => {
                    this.forceUpdate = true;
                    this.changeHeight();
                });
                this.changeBg(index);
                scrollTo(0, 0);
            },
            changeHeight() {
                let that = this;
                this.$nextTick(() => {
                    that.tab_height =
                        Number($(".right_content_parent").height()) >
                        Number(document.body.clientHeight) - 101
                            ? $(".right_content_parent").height() + "px"
                            : Number(document.body.clientHeight) - 101 + "px";
                });
            },
            changeBg(index) {
                if ([1].includes(index)) {
                    document.querySelectorAll('.enterprise-footer,.right_content_parent').forEach(item => {
                        item.style.background = '#f4f8ff'
                    })
                } else {
                    document.querySelectorAll('.enterprise-footer,.right_content_parent').forEach(item => {
                        item.style.background = '#fff'
                    })
                }
            }
        },
    };
</script>

<style scoped lang="scss">
    .ivu-select-selection {
        border-radius: 0 !important;
    }

    .arrow_ld {
        display: inline-block;
        position: absolute;
        right: 0;

        & > span {
            display: inline-block;
            height: 20px;
            width: 20px;
            background-color: #1767E0;
            text-align: center;
            line-height: 20px;
            color: white;
        }
    }

    .qiye,
    .qiye td {
        border: 1px solid #1767E0;
    }

    .qiye {
        border-bottom: none;
        border-right: none;
    }

    .qiye td {
        border-top: none;
        border-left: none;
    }

    .qiye td:nth-child(1) {
        width: 100px;
        text-align: right;
    }

    .qiye td:nth-child(2) {
        width: 310px;
    }

    .qiye td:nth-child(3) {
        width: 130px;
        text-align: right;
    }

    .qiye td:nth-child(4) {
        width: 260px;
    }

    .span_cg {
        font-size: 18px;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .span_yyjs {
        display: inline-block;
        text-align: center;
        font-size: 14px;
        height: 20px;
        line-height: 20px;
        width: 60px;
        border-width: 1px;
        background-color: rgb(254, 164, 100);
        opacity: 0.8;
        overflow: hidden;
        position: absolute;
        right: 20px;
    }

    .ivu-col {
        border: none !important;
    }

    .neirong {
        margin-left: 5px;

        .nei_rong_box {
            height: 620px;
            width: 100%;
            border-bottom: 2px dotted #f5f5f5;
        }

        p {
            display: inline-block;
            margin-bottom: 20px;
            font-size: 14px;
            color: black;
        }
    }

    .bottom_style {
        position: absolute;
        transform: translateX(-50%);
        left: 50%;
        bottom: -110px;
    }

    .common_liucheng:nth-child(1) {
        border: none;
    }

    .common_liucheng {
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        & > span {
            display: block;
            padding-left: 5px;
            padding-top: 38px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            color: rgb(51, 51, 51);
            margin-bottom: 30px;
        }
    }

    .next_style {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .right_real_content {
        margin-bottom: 50px;
        width: 95%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 40px;
    }

    .right_header {
        padding-top: 20px;
        padding-left: 20px;
        margin-bottom: 40px !important;
        border-bottom: 1px solid #e8eaec;
        overflow: hidden;

        span {
            display: block;
            margin-bottom: 20px;
        }
    }

    .button_style {
        display: inline-block;
        background-color: #1767E0;
        z-index: 51;
        text-align: center;
        font-size: 14px;
        color: white;
        padding: 0 27px;
        line-height: 32px;
        height: 32px;
        border-radius: 4px;
    }

    .input_search,
    .input_search:focus {
        border-width: 1px;
        border-color: rgb(55, 197, 207);
        border-style: solid;
        border-radius: 2px;
        background-color: rgb(255, 255, 255);
        box-shadow: 6px 0px 15px 0px rgba(127, 127, 127, 0.1);
        width: 300px;
        height: 40px;
        outline: none;
    }

    .right_content {
        background-color: rgb(255, 255, 255);
        min-width: 1210px;
        height: auto;
    }

    .right_content_parent {
        // width: 90%;
        min-width: 960px;
        // float: left;
        margin-left: 160px;
        // padding-top: 10px;
        position: relative;
        min-height: calc(100% - 46px);
    }

    .left_tab_parent {
        height: 100%;
        min-width: 160px;
        width: 160px;
        background-color: #D5E7FA;
        position: fixed;
        top: 101px;

        .left_tab {
            div {
                height: 61px;
                line-height: 61px;
                font-size: 14px;
                color: #597697;
                padding-left: 10px;
                cursor: pointer;
                border-bottom: 1px solid #BDDBFA;

                &.isChoose {
                    color: #fff;
                    background-color: #1767E0;
                }
            }
        }
    }
</style>
